import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "@reduxjs/toolkit";

// Reducers from each module
// Shared module
import auth from "./../redux/reducers/auth.reducer";
import ui from "./../redux/reducers/ui.reducer";
// Billing Module
import filters from "./../../BillingModule/redux/reducers/filters.reducer";
import metaBilling from "../../BillingModule/redux/reducers/metaBilling.reducer";
import billableHours from "./../../BillingModule/redux/reducers/billableHours.reducer";
import billableItems from "./../../BillingModule/redux/reducers/billableItems.reducer";
import invoices from "./../../BillingModule/redux/reducers/invoices.reducer";
// Products Module
import uiShared from "../../SharedModule/redux/reducers/uiShared.reducer";
import spotlightReportProducts from "./../../ProductsModule/redux/reducers/spotlightReport.reducer";
import metaProducts from "../../ProductsModule/redux/reducers/metaProducts.reducer";
import products from "../../ProductsModule/redux/reducers/products.reducer";
import spotlight from "../../ProductsModule/redux/reducers/spotlight.reducer";
import keyContacts from "../../ProductsModule/redux/reducers/keyContact.reducer";
import successCriteria from "../../ProductsModule/redux/reducers/successCriteria.reducer";
// Times Module
import metaTimes from "../../TimesModule/redux/reducers/metaTimes.reducer";
import times from "../../TimesModule/redux/reducers/times.reducer";
import timesQuery from "../../TimesModule/redux/reducers/timesQuery.reducer";
import queryResults from "../../TimesModule/redux/reducers/queryResult.reducer";
import approvalTime from "../../TimesModule/redux/reducers/approvalTime.reducer";
import entryHourBox from "../../TimesModule/redux/reducers/entryHourBox.reducer";
import approvalTimeByUser from "../../TimesModule/redux/reducers/approveTimeByUser.reducer";
import timeConfirmation from "../../TimesModule/redux/reducers/timesConfirmationReport.reducer";
import timeConfirmationResult from "../../TimesModule/redux/reducers/timesConfirmationResults.reducer";
import whoIsOutReport from "../../TimesModule/redux/reducers/whoIsOutReport.reducer";
import mealBreakReport from "../../TimesModule/redux/reducers/mealBreakReport.reducer";
import home from "../../TimesModule/redux/reducers/home.reducer";

// Avoid persistence when there's a new version.
const APP_VERSION = process.env.REACT_APP_CURRENT_GIT_SHA;
const newVersion = localStorage.APP_VERSION !== APP_VERSION;

if (newVersion) {
  window.location.reload();
}

const persistConfigFilters = {
  key: "filters",
  storage,
};

const persistedFilters = persistReducer(persistConfigFilters, filters);

const persistConfigBillableHours = {
  key: "billableHours",
  storage,
};

const persistedBillableHours = persistReducer(
  persistConfigBillableHours,
  billableHours
);

export default combineReducers({
  //shared
  auth: auth,
  ui: ui,
  //billing
  metaBilling: metaBilling,
  filters: persistedFilters,
  billableHours: persistedBillableHours,
  billableItems: billableItems,
  invoices: invoices,
  //products
  uiShared: uiShared,
  spotlightReportProducts: spotlightReportProducts,
  metaProducts: metaProducts,
  products: products,
  spotlight: spotlight,
  keyContacts: keyContacts,
  successCriteria: successCriteria,
  // times
  metaTimes: metaTimes,
  times: times,
  timesQuery: timesQuery,
  queryResults: queryResults,
  approvalTime: approvalTime,
  entryHourBox: entryHourBox,
  approvalTimeByUser: approvalTimeByUser,
  timeConfirmation: timeConfirmation,
  timeConfirmationResult: timeConfirmationResult,
  whoIsOutReport: whoIsOutReport,
  mealBreakReport: mealBreakReport,
  home: home,
});
