import { useDispatch, useSelector } from "react-redux";
import { actualUIState } from "../../SharedModule/redux/reducers/uiShared.reducer";
import { hasTimeAdminAccess } from "../utils/timesUtils";
import {
  GetMealBreakUserList,
  mealBreakReportInfo,
  setLoading,
} from "../redux/reducers/mealBreakReport.reducer";
import { useCallback, useEffect } from "react";
import { TimesheetsService } from "../services/timesheets";
import { PayPeriod } from "../types";

export const useMealBreakReport = (
  selectedPayPeriod: PayPeriod | undefined | any
) => {
  const dispatch = useDispatch();

  const { userRolesTimesModule, asuiteTimesPermissions, isLoadingUserInfo } =
    useSelector(actualUIState);
  // times module admin
  const isTimeAdmin = hasTimeAdminAccess(userRolesTimesModule);
  const mealBreakUserList = useSelector(GetMealBreakUserList);

  const mealBreakReportResults = useCallback(async () => {
    dispatch(setLoading(true));
    if (selectedPayPeriod) {
      const results = await TimesheetsService.getMealBreakReportInfo(
        selectedPayPeriod
      );
      dispatch(mealBreakReportInfo(results));
    }
  }, [dispatch]);

  useEffect(() => {
    mealBreakReportResults();
  }, [mealBreakReportResults]);

  return {
    mealBreakUserList,
    userRolesTimesModule,
    asuiteTimesPermissions,
    isLoadingUserInfo,
    isTimeAdmin,
  };
};
