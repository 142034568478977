import { createSlice } from "@reduxjs/toolkit";
import {
  getIsoFormatStringWithTimeZone,
  getLastWeekDay,
  THIS_MONTHS_START,
  YESTERDAY,
} from "../../../SharedModule/utils/dateUtils";

type costCentersID = {
  label: string;
  level: number;
  value: number;
};

type TimeConfirmationState = {
  keepFilters: boolean;
  filters: {
    allCountries: boolean;
    startDate: string;
    endDate: string;
    costCentersIDs: Array<costCentersID> | null;
  };
};

const today = new Date();

const initialState: TimeConfirmationState = {
  keepFilters: false,
  filters: {
    allCountries: true,
    startDate: THIS_MONTHS_START.toString(),
    endDate:
      today.getDay() !== 0 && today.getDay() !== 1 && today.getDay() !== 6
        ? YESTERDAY.toString()
        : getIsoFormatStringWithTimeZone(getLastWeekDay(2, today)), // should get previous friday if yesterday is monday
    costCentersIDs: null,
  },
};

export const timeConfirmationSlice = createSlice({
  name: "timeConfirmation",
  initialState,
  reducers: {
    keepFilters: (state, action) => {
      state.keepFilters = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFilters, keepFilters } = timeConfirmationSlice.actions;

export const SelectFilters = (state: any) => {
  return state.timeConfirmation.keepFilters
    ? state.timeConfirmation.filters
    : initialState.filters;
};

export const GetFilters = (state: any) => {
  return state.timeConfirmation.filters;
};

export const SelectInitialStateFilters = () => {
  return initialState.filters;
};

export default timeConfirmationSlice.reducer;
