import React from "react";
import "../../../styles/main.module.scss";
import { shallowEqual, useSelector } from "react-redux";
import {
  selectAdvancedFilters,
  selectFilters,
} from "./../../redux/reducers/filters.reducer";
import BillableHoursTable from "./BillableHoursTable";
import {
  selectBillableHoursHeaders,
  selectProjectWOTotalAmount,
} from "./../../redux/reducers/billableHours.reducer";
import { currencyFormat } from "../../../SharedModule/utils/formatters";
import { Spinner } from "../../../SharedModule/components/Spinner";
import image from "./../../../styles/legacy/404.png";
import { NoContent } from "../../../SharedModule/components/NoContent";

const projectsBaseUrl = process.env.REACT_APP_ASUITE_PROJECT_URI;
const workOrderBaseUrl = process.env.REACT_APP_ASUITE_WO_URI;

const BillableHours = ({ loading }: { loading: boolean }) => {
  const filters = useSelector(selectFilters, shallowEqual);
  const advancedFilters = useSelector(selectAdvancedFilters, shallowEqual);
  const totalAmount = useSelector(selectProjectWOTotalAmount);
  const billableHours = useSelector(
    selectBillableHoursHeaders,
    shallowEqual
  ).billableHoursHeaders;

  // ASUITE11-2284: getTimeEntriesDetail function now needs uuid instead of previous ASuiteId
  const params = {
    dateFrom: filters.dateFrom,
    dateTo: filters.dateTo,
    employeeIds: advancedFilters.employees?.map((item) => item.uuid),
    invoiceOwners: advancedFilters.invoiceOwners?.map((item) => item.value),
    projectIds: advancedFilters.projects?.map((item) => item.value),
    workOrderIds: advancedFilters.workOrders?.map((item) => item.value),
    accountIds: advancedFilters.clients?.map((item) => item.value),
    billingCycleIds: filters.billingCycles?.map((item: any) => item.value),
    includeWoAttachedToProject: filters.includeWO,
  };

  if (loading)
    return (
      <div className="mt-5">
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      </div>
    );

  if (!loading && !billableHours.length)
    return (
      <NoContent
        title="No Entries"
        text="No entries were found matching your query request."
        image={image}
        style={{ margin: "3% auto" }}
      />
    );

  return (
    <>
      {billableHours && billableHours.length > 0 ? (
        billableHours.map((item) => {
          const id =
            item.projectId !== null ? item.projectId : item.workOrderId;

          return (
            <table key={id} className="white-table-header-background w-100">
              <thead className="align-middle">
                <tr className="row fw-bold text-body pb-3 pt-4 sticky sticky-with-tabs bc-white">
                  <td className="col-md-auto">{item.accountName}</td>
                  {item.workOrderId && (
                    <td className="col">
                      WO:
                      <a
                        href={`${workOrderBaseUrl}${item.workOrderId}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-decoration-none ms-1"
                      >
                        #{item.workOrderId} - {item.workOrderDescription}
                      </a>
                    </td>
                  )}
                  {item.projectId && (
                    <td className="col">
                      Project:
                      <a
                        href={`${projectsBaseUrl}${item.projectId}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-decoration-none ms-1"
                      >
                        #{item.projectId} - {item.projectName}
                      </a>
                    </td>
                  )}
                  <td className="col-md-auto">
                    Total Price: {currencyFormat(totalAmount[item.uuid])}
                  </td>
                </tr>
                <BillableHoursTable
                  key={item.uuid}
                  id={id}
                  uuid={item.uuid}
                  isProject={item.isProject}
                  accountId={item.accountId}
                  dateFrom={params.dateFrom}
                  dateTo={params.dateTo}
                  employeeIds={params.employeeIds}
                  invoiceOwners={params.invoiceOwners}
                  billingCycleIds={params.billingCycleIds}
                  includeWoAttachedToProject={params.includeWoAttachedToProject}
                />
              </thead>
            </table>
          );
        })
      ) : (
        <div className="mt-5">No billable hours to list</div>
      )}
    </>
  );
};

export const MemoedBillableHours = React.memo(BillableHours);
