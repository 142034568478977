import { DateTime } from "luxon";
import { CustomWeekDay } from "../../TimesModule/types";

// Dates
export const NOW = DateTime.now();
export const YESTERDAY = NOW.get("day") === 1 ? NOW : NOW.minus({ day: 1 });
export const THIS_YEAR_START = NOW.startOf("year");
export const THIS_MONTHS_START = NOW.startOf("month");
export const THIS_MONTHS_END = NOW.endOf("month");
export const LAST_MONTHS_START = NOW.minus({ month: 1 }).startOf("month");
export const LAST_MONTHS_END = NOW.minus({ month: 1 }).endOf("month");

export const getBusinessDateCount = (startDate, endDate) => {
  let elapsed, daysBeforeFirstSaturday, daysAfterLastSunday;
  const ifThen = (a, b, c) => (a === b ? c : a);

  elapsed = endDate - startDate;
  elapsed /= 86400000;

  daysBeforeFirstSaturday = (7 - startDate.getDay()) % 7;
  daysAfterLastSunday = endDate.getDay();

  elapsed -= daysBeforeFirstSaturday + daysAfterLastSunday;
  elapsed = (elapsed / 7) * 5;
  elapsed +=
    ifThen(daysBeforeFirstSaturday - 1, -1, 0) +
    ifThen(daysAfterLastSunday, 6, 5);

  return Math.floor(elapsed);
};

export const getDateFromISOFormat = (evalDate: string) => {
  return evalDate.split("T")[0];
};

export const getDateFromDateString = (dateString: string) => {
  let date = dateString.split("T")[0].split("-");
  return new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]));
};

export const getIsoFormatStringWithTimeZone = (paramDate: Date) => {
  const timezoneOffsetInMinutes = paramDate.getTimezoneOffset(); // Get the offset in minutes
  const localDate = new Date(
    paramDate.getTime() - timezoneOffsetInMinutes * 60 * 1000
  );
  return localDate.toISOString().slice(0, -1); // Remove the "Z"
};

export const getShortDayName = (index: number) => {
  switch (index) {
    case 0:
      return "SUN";
    case 1:
      return "MON";
    case 2:
      return "TUE";
    case 3:
      return "WED";
    case 4:
      return "THU";
    case 5:
      return "FRI";
    default:
      return "SAT";
  }
};

export const isToday = (date: Date) => {
  const today: Date = new Date();
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
};

export const isYesterday = (date: Date) => {
  const today: Date = new Date();
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getUTCDate() === today.getUTCDate() - 1
  );
};

export const isSameDay = (dateString: string, d2: Date) => {
  let d1 = DateTime.fromISO(dateString, { zone: "utc" }).setLocale("en-US");
  return (
    d1.year === d2.getFullYear() &&
    d1.month - 1 === d2.getMonth() &&
    d1.day === d2.getDate()
  );
};

export const isWeekday = (date: Date) => date.getDay() % 6 !== 0;

export const isHoliday = (day: Date, holidays: string[]) => {
  let response = false;
  if (holidays.length > 0) {
    holidays.forEach((element) => {
      if (isSameDay(element, day)) {
        response = true;
      }
    });
  }
  return response;
};

export const getPreviousWorkableDay = (value: number, holidays: string[]) => {
  let previousDay: Date = new Date(
    new Date().setDate(new Date().getDate() - value)
  );
  if (isWeekday(previousDay) && !isHoliday(previousDay, holidays)) {
    return new Date(previousDay.setHours(0, 0, 0, 0));
  }
  return getPreviousWorkableDay(value + 1, holidays);
};

export const getWeekFullDate = (
  actualWeek: CustomWeekDay[],
  dayNumber: number
) => {
  let result;
  actualWeek.forEach((element) => {
    if (element.numDay === dayNumber) {
      result = element.fullDate;
    }
  });
  return result;
};

export const isDateAfterToday = (date: Date) => {
  let today = new Date();
  if (date > today) {
    return true;
  } else {
    return false;
  }
};

export const isTodayBetween1stAnd3rdBusinessDay = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  let businessDays: any[] = [];
  let dayCount = 0;

  // Start from the 1st day of the month and find the first 3 business days
  for (let day = 1; day <= 31; day++) {
    let currentDate = new Date(year, month, day);

    // Stop if we are beyond the current month
    if (currentDate.getMonth() !== month) break;

    // Check if the current date is a weekday (Monday to Friday)
    const dayOfWeek = currentDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      // 0 is Sunday, 6 is Saturday
      businessDays.push(currentDate);
      dayCount++;

      // Stop once we have the first 3 business days
      if (dayCount === 3) break;
    }
  }

  // Check if today is one of the first 3 business days
  return businessDays.some(
    (businessDay) => businessDay.getDate() === today.getDate()
  );
};

export const getPrevSunday = (pageDate: Date) => {
  let actualDate = new Date(
    pageDate.getFullYear(),
    pageDate.getMonth(),
    pageDate.getDate()
  );
  return new Date(
    actualDate.setDate(actualDate.getDate() - actualDate.getDay())
  );
};

// get next day from sunday
export const getNextWeekDay = (days: number, pageDate: Date) => {
  let sunday = getPrevSunday(pageDate);
  let actualDate = new Date(
    sunday.getFullYear(),
    sunday.getMonth(),
    sunday.getDate()
  );
  return new Date(actualDate.setDate(actualDate.getDate() + days));
};

// days will be number to discount
export const getLastWeekDay = (days: number, pageDate: Date) => {
  let sunday = getPrevSunday(pageDate);
  let actualDate = new Date(
    sunday.getFullYear(),
    sunday.getMonth(),
    sunday.getDate()
  );
  return new Date(actualDate.setDate(actualDate.getDate() - days));
};

// last workable setted with last hour
export const getLastWorkableDay = () => {
  return new Date(getNextWeekDay(6, new Date()).setHours(23, 59, 59));
};

// improve logic to check only one date if some of them are null
export const isBetweenTwoDates = (
  dateToCompare: string,
  minorDate: Date | null,
  greaterDate: Date | null
) => {
  let response: boolean = false;
  if (minorDate && greaterDate) {
    response =
      getDateFromISOFormat(getIsoFormatStringWithTimeZone(minorDate)) <=
        getDateFromISOFormat(dateToCompare) &&
      getDateFromISOFormat(dateToCompare) <=
        getDateFromISOFormat(getIsoFormatStringWithTimeZone(greaterDate));
  } else if (!minorDate && greaterDate) {
    response =
      getDateFromISOFormat(dateToCompare) <=
      getDateFromISOFormat(getIsoFormatStringWithTimeZone(greaterDate));
  } else if (minorDate && !greaterDate) {
    response =
      getDateFromISOFormat(getIsoFormatStringWithTimeZone(minorDate)) <=
      getDateFromISOFormat(dateToCompare);
  }
  return response;
};
