import { useDispatch, useSelector } from "react-redux";
import { selectMetaTimesData } from "../redux/reducers/metaTimes.reducer";
import {
  hasTimeAdminAccess,
  preparedFiltersWhoIsOut,
} from "../utils/timesUtils";
import {
  GetWhoIsOutUserList,
  IsLoading,
  loadWhoIsOutInfo,
  setLoading,
} from "../redux/reducers/whoIsOutReport.reducer";
import { useCallback, useEffect } from "react";
import { TimesheetsService } from "../services/timesheets";
import { actualUIState } from "../../SharedModule/redux/reducers/uiShared.reducer";

export const useWhoIsOutReport = (
  filters: any,
  isLoadingUserInfo: boolean
) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(IsLoading);
  const MetaTimesInfo = useSelector(selectMetaTimesData);
  const whoIsOutUserList = useSelector(GetWhoIsOutUserList);

  // times module admin
  const { userRolesTimesModule } = useSelector(actualUIState);
  const isTimeAdmin = hasTimeAdminAccess(userRolesTimesModule);

  const filtersForRequest = preparedFiltersWhoIsOut(filters);

  const loadWhoIsOutResults = useCallback(async () => {
    dispatch(setLoading(true));
    if (!isLoadingUserInfo) {
      const results = await TimesheetsService.getWhoisOutInfo(
        filtersForRequest
      );
      dispatch(loadWhoIsOutInfo(results.data));
    }
  }, [dispatch, filters, isLoadingUserInfo]);

  useEffect(() => {
    loadWhoIsOutResults();
  }, [loadWhoIsOutResults]);

  return {
    isLoading,
    MetaTimesInfo,
    whoIsOutUserList,
    filters,
    isTimeAdmin,
  };
};
