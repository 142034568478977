import { useMatomo } from "@jonkoops/matomo-tracker-react";
import Footer from "../../SharedModule/components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  ROUTE_TIMESHEETS_REPORTS,
  ROUTE_WHO_IS_OUT_REPORT,
  TIME_ADMIN_ACCESS,
} from "../../SharedModule/utils/constants";
import { Link } from "react-router-dom";
import { Spinner } from "../../SharedModule/components/Spinner";
import { checkDataIsLoaded } from "../utils/timesUtils";
import eventHandler from "../../SharedModule/utils/eventHandler";
import { setActiveTab } from "../../SharedModule/redux/reducers/uiShared.reducer";
import { verifyLinkAccess } from "../../SharedModule/utils/accessUtils";
import { BiLeftArrowAlt } from "react-icons/bi";
import { ForbiddenPage } from "../../SharedModule/pages/ForbiddenPage";
import { Select } from "../../SharedModule/components/Select";
import { useMealBreakReport } from "../hooks/useMealBreakReport";
import { selectMetaTimesData } from "../redux/reducers/metaTimes.reducer";
import { yearPayPeriodsToDropdown } from "../utils/mealBreakHours";

export const MealBreakReportsPage = () => {
  const { trackPageView } = useMatomo();
  const dispatch = useDispatch();

  const MetaTimesInfo = useSelector(selectMetaTimesData);

  let isLoading = false;

  let listOfPayPeriods = yearPayPeriodsToDropdown(MetaTimesInfo.yearPayPeriods);

  const [selectedPayPeriod, setSelectedPayPeriod] = useState(
    null
  );

  const {
    mealBreakUserList,
    userRolesTimesModule,
    asuiteTimesPermissions,
    isLoadingUserInfo,
    isTimeAdmin,
  } = useMealBreakReport(null);


  useEffect(() => {
    document.title = "The Hub - Meal Break Report";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    // should go in every times page
    if (!checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)) {
      eventHandler.dispatch("loadTimesStaticInfo", {
        isTimeAdmin: isTimeAdmin,
      });
    }

    dispatch(setActiveTab(ROUTE_TIMESHEETS_REPORTS));
  }, [trackPageView, isTimeAdmin]);

  const submit = () => {
    console.log("submit");
  };

  return (
    <>
      {isLoadingUserInfo && (
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      )}
      {!isLoadingUserInfo &&
        !verifyLinkAccess(
          true,
          false,
          TIME_ADMIN_ACCESS,
          [],
          userRolesTimesModule,
          asuiteTimesPermissions
        ) && <ForbiddenPage />}
      {!isLoadingUserInfo &&
        verifyLinkAccess(
          true,
          false,
          TIME_ADMIN_ACCESS,
          [],
          userRolesTimesModule,
          asuiteTimesPermissions
        ) && (
          <div className="content">
            <div className="mt-2 button-header">
              <Link
                className="back-button-container text-decoration-none"
                to={ROUTE_TIMESHEETS_REPORTS}
              >
                <BiLeftArrowAlt className="back-button-arrow" />
                <span className="back-button-text">Back to Time reports</span>
              </Link>
            </div>
            <div className="d-flex flex-wrap">
              <h2 className="flex-fill">Meal Break report</h2>
            </div>

            <form className="card entry-hour-box-background p-4 mt-3 mb-5">
              <div className="d-flex mb-4 justify-content-between">
                <div className="flex-column col-sm-2">
                  <label className="col-form-label">Pay Period</label>
                  <Select
                    placeholder="All"
                    options={listOfPayPeriods}
                    value={null}
                    onChange={(payPeriod) => setSelectedPayPeriod(payPeriod)}
                    isDisabled={
                      isLoading ||
                      !checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)
                    }
                  />
                </div>
                <div
                  className="d-flex flex-column"
                  style={{ marginTop: "35px" }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={() => submit()}
                    disabled={true}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>

            <hr className="header-separator" />

            <table
              className="grey-table-header-background time-confirmation table my-5"
              style={{ width: "100%" }}
            >
              <thead className="align-middle sticky-header sticky">
                <tr></tr>
              </thead>
              <tbody></tbody>
            </table>

            <Footer />
          </div>
        )}
    </>
  );
};
