import { getDateFromDateString } from "../../SharedModule/utils/dateUtils";

export const timeFrameOptios = [
  {
    value: 1,
    label: "Next 30 days",
  },
  {
    value: 2,
    label: "Next 60 days",
  },
  {
    value: 3,
    label: "Next 90 days",
  },
  {
    value: 4,
    label: "End of year",
  },
];

// who is out report sorting by column grouped by
export const compare = (condition: boolean) => {
  return condition ? 1 : -1;
};

export const groupDataByPerson = (data) => {
  const grouped = {};
  if (data) {
    data.forEach((item) => {
      if (!grouped[item.person]) {
        grouped[item.person] = [];
      }
      grouped[item.person].push(item);
    });
    // Sort each group by date
    Object.keys(grouped).forEach((person) => {
      grouped[person].sort((a: any, b: any) =>
        compare(getDateFromDateString(a.date) > getDateFromDateString(b.date))
      );
    });
  }
  return grouped;
};

export const groupDataByDate = (data) => {
  const grouped = {};
  if (data) {
    data.forEach((item) => {
      if (!grouped[item.date]) {
        grouped[item.date] = [];
      }
      grouped[item.date].push(item);
    });
    // Sort each group by person
    Object.keys(grouped).forEach((date) => {
      grouped[date].sort((a: any, b: any) => a.person - b.person);
    });
  }
  return grouped;
};
