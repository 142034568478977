import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect, useRef, useState } from "react";
import {
  camelCaseToWords,
  checkDataIsLoaded,
  firstLetterToLower,
  transformValue,
  navigateTo,
  getCorrectHoursType,
  iconEditEnabled,
  iconDeleteEnabled,
} from "../utils/timesUtils";
import eventHandler from "../../SharedModule/utils/eventHandler";
import { useDispatch, useSelector } from "react-redux";
import {
  ROUTE_APPROVE_TIME_TIMESHEETS,
  THE_HUB,
  BILLABLE,
  UNPAID,
  nonBillableIds,
  LOCKED,
  PROJ_UNPAID_TIME_OFF_ID,
} from "../../SharedModule/utils/constants";
import {
  dateFormatEntryEdit,
  nameDayDateFormat,
  quantityFormat,
} from "../../SharedModule/utils/formatters";
import { SummaryTotals } from "../components/SummaryTotals";
import {
  BiLeftArrowAlt,
  BiLockAlt,
  BiInfoCircle,
  BiEditAlt,
  BiCalendar,
  BiTrash,
} from "react-icons/bi";
import {
  resetFilters,
  selectOrderedBy,
} from "../redux/reducers/approvalTime.reducer";
import { useApproveTimeByUser } from "../hooks/useApproveTimeByUser";
import { DayTotals } from "../components/DayTotals";
import Footer from "../../SharedModule/components/Footer";
import { TimeEntryApprovalTime } from "../types";
import { TimesheetsService } from "../services/timesheets";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { ModalPortal } from "../../SharedModule/components/ModalPortal";
import { ModalMoreInfoTimeEntry } from "../components/ModalMoreInfoTimeEntry";
import { ModalTimeEntry } from "../components/ModalTimeEntry";
import { ModalDeleteTimeEntry } from "../components/ModalDeleteTimeEntry";
import { useTopNavigationInfo } from "../../SharedModule/hooks/useTopNavigationInfo";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { ActionsButton } from "../components/ActionsButton";
import { ModalActionNonBillable } from "../components/ModalActionNonBillable";
import { ModalActionChangeDate } from "../components/ModalActionChangeDate";
import { ModalActionChangeProject } from "../components/ModalActionChangeProject";
import {
  setNextPersonIndex,
  setLoading,
  setSearchState,
} from "../redux/reducers/approveTimeByUser.reducer";
import { CheckSkeleton } from "../../SharedModule/utils/checkSkeleton";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "../../SharedModule/components/Spinner";
import {
  getDateFromISOFormat,
  getLastWorkableDay,
  getPreviousWorkableDay,
} from "../../SharedModule/utils/dateUtils";

export const ApproveTimeByUserPage = () => {
  const { trackPageView } = useMatomo();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [entriesSelected, setEntriesSelected] = useState<any>([]);
  const [entryActions, setEntryActions] = useState(undefined);

  const [activeEntry, setActiveEntry] = useState<TimeEntryApprovalTime>(Object);
  const [timeEntriesListByUser, setTimeEntriesListByUser] = useState([]);
  const [userListId, setUserListId] = useState<string[]>([]);

  // Imported to use, when edit or delete a time entry from actual user, should update hours in header
  const { loadInfo } = useTopNavigationInfo();

  let approveAndViewNextClicked: boolean = false;
  let actualNextIndex: number = -1;

  const today = new Date();
  let lastWorkableDay: Date = getLastWorkableDay();

  const {
    isTimeAdmin,
    isSuperTimeAdmin,
    isSupervisorOrPO,
    summary,
    isLoading,
    timeEntriesList,
    activeUserId,
    holidays,
    selectProjectOrWorkOrder,
    MetaTimesInfo,
    searchState,
    nextPersonIndex,
    loadPageInfo,
  } = useApproveTimeByUser();

  let previousWorkableDay = getPreviousWorkableDay(1, holidays);

  // When user is not admin, should not see PROJ_UNPAID_TIME_OFF_ID - ASUITE11-2464
  let selectProjectOrWorkOrderAfterFilter = selectProjectOrWorkOrder;
  if (!isTimeAdmin && selectProjectOrWorkOrder[1].options.length > 0) {
    selectProjectOrWorkOrderAfterFilter[1].options =
      selectProjectOrWorkOrder[1].options.filter(
        (project) => project.entityId !== PROJ_UNPAID_TIME_OFF_ID
      );
  }

  let listFiltersTouched: any[] = [];
  if (searchState?.filters) {
    for (const property in searchState.filters) {
      if (searchState.filters[property] && property !== "endDate") {
        listFiltersTouched.push({
          name: camelCaseToWords(property),
          value: transformValue(property, searchState.filters[property]),
        });
      }
    }
  }

  const { userList } = useSelector(selectOrderedBy);

  useEffect(() => {
    document.title = "The Hub - Approve Time By User";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    // to avoid issue on refresh from browser
    if (!searchState.user) {
      navigate(ROUTE_APPROVE_TIME_TIMESHEETS);
    }

    // should go in every times page
    if (!checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)) {
      eventHandler.dispatch("loadTimesStaticInfo", {
        isTimeAdmin: isTimeAdmin,
      });
    }

    return () => {
      dispatch(setNextPersonIndex(-1));
      // reset filters if user got to another page
      if (window.location.pathname !== ROUTE_APPROVE_TIME_TIMESHEETS) {
        //   clean filters
        dispatch(resetFilters());
      }
    };
  }, [trackPageView, isTimeAdmin]);

  const isSelected = (entry: TimeEntryApprovalTime) => {
    let response = entriesSelected.indexOf(entry);
    return response > -1;
  };

  const updatedSelected = (entry: TimeEntryApprovalTime) => {
    let response = entriesSelected.indexOf(entry);
    if (response < 0) {
      const dataUpdated = [entry, ...entriesSelected];
      setEntriesSelected(dataUpdated);
    } else {
      setEntriesSelected(
        entriesSelected.filter((item) => item.id !== entry.id)
      );
    }
  };

  const updateSelectedByDay = (date: string, value: boolean) => {
    let response = timeEntriesList[date].filter(
      (entry) => entry.source === THE_HUB
    );
    if (value) {
      const dataUpdated = [...response, ...entriesSelected];
      setEntriesSelected(dataUpdated);
    } else {
      setEntriesSelected(
        entriesSelected.filter((item) => item.entryDate !== date)
      );
    }
  };

  const updateAllSelected = () => {
    let response: TimeEntryApprovalTime[] = [];
    Object.keys(timeEntriesList).forEach((keyName) => {
      timeEntriesList[keyName].forEach((element) => {
        if (element.source === THE_HUB) {
          response.push(element);
        }
      });
    });
    setEntriesSelected(response);
  };

  const getUserName = (userId: string) => {
    // to avoid issue on refresh from browser on query result page
    if (MetaTimesInfo.timeUsers) {
      let response: any = MetaTimesInfo.timeUsers?.filter(
        (user) => user.value === userId
      );
      return response[0].label;
    } else {
      navigate(ROUTE_APPROVE_TIME_TIMESHEETS);
    }
  };

  // Modal More Info config
  const modalMoreInfoRef = useRef();
  const showMoreInfoModal = () => {
    (modalMoreInfoRef.current as any).show(true);
  };
  const closeMoreInfoModal = () => {
    (modalMoreInfoRef.current as any).show(false);
  };

  const showMoreInfo = async (entry: TimeEntryApprovalTime) => {
    await TimesheetsService.getTimeEntryInfo(entry.timeEntryId)
      .then((response) => {
        if (response.data.data.length > 0) {
          setEntryActions(response.data.data);
          showMoreInfoModal();
        } else {
          dispatch(
            addToast({ mode: "error", message: "Time entry was not found" })
          );
        }
      })
      .catch((error) => {
        dispatch(addToast({ mode: "error", message: error }));
      });
  };

  // Modal config
  const modalRef = useRef();
  const showModal = () => {
    (modalRef.current as any).show(true);
  };
  const closeModal = () => {
    (modalRef.current as any).show(false);
  };

  const editEntry = (entry: TimeEntryApprovalTime) => {
    setActiveEntry({
      ...entry,
      entryDate: getDateFromISOFormat(entry.entryDate),
    });
    // select time entries by same day and same user as actual entry
    setTimeEntriesListByUser(
      timeEntriesList[entry.entryDate].filter(
        (elem) => elem.userId === entry.userId
      )
    );
    showModal();
  };

  // refresh info page, also with condition if user should be update header
  const refreshPage = () => {
    // if user is not admin or is admin and is adding own hours
    if (!isTimeAdmin || activeUserId === activeEntry.userId) {
      loadInfo();
    }
    loadPageInfo();
  };

  // Modal Delete config
  const modalDeleteRef = useRef();
  const showDeleteModal = () => {
    (modalDeleteRef.current as any).show(true);
  };
  const closeDeleteModal = () => {
    (modalDeleteRef.current as any).show(false);
  };

  const deleteEntry = (entry: TimeEntryApprovalTime) => {
    setActiveEntry(entry);
    showDeleteModal();
  };

  const deleteEntryModal = async () => {
    closeDeleteModal();
    dispatch(setLoading(true));
    await TimesheetsService.deleteTimesEntry(activeEntry.timeEntryId)
      .then((response) => {
        dispatch(addToast({ mode: "success", message: response.data.message }));
        refreshPage();
      })
      .catch((error) => {
        dispatch(addToast({ mode: "error", message: error }));
      });
  };

  // Modal Action billable
  const ModalActionNonBillableRef = useRef();
  const showActionNonBillaleModal = () => {
    (ModalActionNonBillableRef.current as any).show(true);
  };
  const closeActionNonBillaleModal = () => {
    (ModalActionNonBillableRef.current as any).show(false);
  };

  const loadBodyToupdateTimeEntries = (action: number, value: any) => {
    // 1-billable 2-nonBillable 3-approved 4-unapprove 5=moveDate 6-moveProject
    let body = {
      timeEntriesIds:
        action !== 3
          ? entriesSelected.map((elem) => elem.timeEntryId)
          : entriesSelected
              .filter((elem) => elem.categoryId !== 3)
              .map((elem) => elem.timeEntryId),
      action: {
        id: action,
        nonBillableReasonId: action === 2 ? value : null,
        entryDate: action === 5 ? value : null,
        entityId: action === 6 ? value.entityId : null,
        categoryId: action === 6 ? value.categoryId : null,
      },
    };
    return body;
  };

  const logicAfterApprove = () => {
    if (userList) {
      // check what button was clicked: "Approve" or "Approve & view next person"
      if (approveAndViewNextClicked) {
        if (actualNextIndex > -1) {
          dispatch(
            setSearchState({
              user: userList[actualNextIndex],
              filters: searchState.filters,
              hoursType: searchState.hoursType,
            })
          );
          approveAndViewNextClicked = false;
          dispatch(setNextPersonIndex(actualNextIndex));
        } else {
          // Should go back to approve time page
          dispatch(setNextPersonIndex(-1));
          navigate(ROUTE_APPROVE_TIME_TIMESHEETS);
        }
      } else {
        dispatch(setNextPersonIndex(-1));
        loadPageInfo();
      }

      // clean entries selected
      setEntriesSelected([]);
    }
  };

  const updateEntriesSelected = async (action: number, value: any) => {
    // set loading to true
    dispatch(setLoading(true));

    // close modals
    if (action === 5) {
      closeActionChangeDateModal();
    } else if (action === 6) {
      closeActionChangeProjectModal();
    } else if (action === 2) {
      closeActionNonBillaleModal();
    }

    let body = loadBodyToupdateTimeEntries(action, value);

    if (body.timeEntriesIds.length > 0) {
      await TimesheetsService.updateTimeEntries(body)
        .then((response) => {
          if (body.timeEntriesIds.length < entriesSelected.length) {
            dispatch(
              addToast({
                mode: "warning",
                message: "Time is approved when the Work Order is Closed",
              })
            );
          }
          dispatch(
            addToast({ mode: "success", message: response.data.message })
          );
          logicAfterApprove();
        })
        .catch((error) => {
          dispatch(addToast({ mode: "error", message: error }));
        });
    } else {
      dispatch(
        addToast({
          mode: "warning",
          message: "Time is approved when the Work Order is Closed",
        })
      );
      logicAfterApprove();
    }
  };

  // Modal Action change date
  const modalActionChangeDateRef = useRef();
  const showActionChangeDateModal = () => {
    (modalActionChangeDateRef.current as any).show(true);
  };
  const closeActionChangeDateModal = () => {
    (modalActionChangeDateRef.current as any).show(false);
  };

  // Modal Action change project
  const modalActionChangeProjectRef = useRef();
  const showActionChangeProjectModal = () => {
    let partailUserListId: string[] = [];
    entriesSelected.forEach((entry: TimeEntryApprovalTime) => {
      if (!partailUserListId.includes(entry.userId)) {
        partailUserListId.push(entry.userId);
      }
    });
    setUserListId(partailUserListId);
    (modalActionChangeProjectRef.current as any).show(true);
  };
  const closeActionChangeProjectModal = () => {
    (modalActionChangeProjectRef.current as any).show(false);
  };

  const updateAndViewNext = () => {
    // logic for next user
    if (userList) {
      approveAndViewNextClicked = true;
      let newIndex: number = -1;
      if (nextPersonIndex > -1) {
        newIndex = nextPersonIndex;
      } else {
        newIndex = userList?.findIndex(
          (elem) => elem.userId === searchState.user.userId
        );
      }
      if (newIndex >= 0 && newIndex < userList?.length - 1) {
        actualNextIndex = newIndex + 1;
        updateEntriesSelected(3, null);
      } else if (newIndex === userList?.length - 1) {
        // last user in array
        actualNextIndex = -1;
        updateEntriesSelected(3, null);
      }
    }
  };

  return (
    <>
      <ModalPortal ref={modalMoreInfoRef}>
        <ModalMoreInfoTimeEntry
          btnCancel={closeMoreInfoModal}
          type="moreInfoModal"
          actions={entryActions}
        />
      </ModalPortal>
      <ModalPortal ref={modalRef}>
        <ModalTimeEntry
          title="Modal Entry"
          projectList={selectProjectOrWorkOrderAfterFilter}
          btnCancel={closeModal}
          reloadPage={refreshPage}
          type="formModal"
          editMode={true}
          entry={activeEntry}
          userIdParam={activeEntry.userId}
          holidays={holidays}
          deleteEntry={deleteEntry}
          timeEntriesList={timeEntriesListByUser}
          isQueryPage={true}
        />
      </ModalPortal>
      <ModalPortal ref={modalDeleteRef}>
        <ModalDeleteTimeEntry
          btnCancel={closeDeleteModal}
          btnAction={deleteEntryModal}
        />
      </ModalPortal>
      <ModalPortal ref={ModalActionNonBillableRef}>
        <ModalActionNonBillable
          btnCancel={closeActionNonBillaleModal}
          btnAction={updateEntriesSelected}
          nonBillableReasons={MetaTimesInfo.nonBillableReasons}
        />
      </ModalPortal>
      <ModalPortal ref={modalActionChangeDateRef}>
        <ModalActionChangeDate
          btnCancel={closeActionChangeDateModal}
          btnAction={updateEntriesSelected}
        />
      </ModalPortal>
      <ModalPortal ref={modalActionChangeProjectRef}>
        <ModalActionChangeProject
          btnCancel={closeActionChangeProjectModal}
          btnAction={updateEntriesSelected}
          userListId={userListId}
        />
      </ModalPortal>

      <div className="content">
        <div className="mt-2 button-header">
          <Link
            className="back-button-container text-decoration-none"
            to={ROUTE_APPROVE_TIME_TIMESHEETS}
          >
            <BiLeftArrowAlt className="back-button-arrow" />
            <span className="back-button-text">Back to Approve time</span>
          </Link>
        </div>
        <CheckSkeleton isLoading={isLoading} classes="w-50 fs-3">
          <div className="d-flex flex-wrap mb-1">
            {searchState && searchState.user && (
              <h2 className="flex-fill">
                {searchState.user.userName}{" "}
                <span>({getCorrectHoursType(searchState.hoursType)})</span>
              </h2>
            )}
          </div>
        </CheckSkeleton>
        <div className="flex-container flex-wrap">
          <div className="col-lg-4 col-12 d-inline-flex mb-3">
            <div className="col-4">From:</div>
            {searchState && searchState.user && (
              <div className="col-7 fw-bold">{`${dateFormatEntryEdit(
                searchState.user.startDate
              )} - ${dateFormatEntryEdit(searchState.filters.endDate)}`}</div>
            )}
          </div>
          {listFiltersTouched &&
            listFiltersTouched.length > 0 &&
            listFiltersTouched.map((filter: any) => {
              return (
                <div
                  className="col-lg-4 col-12 d-inline-flex mb-3"
                  key={filter.name}
                >
                  <div className="col-4">{filter.name}:</div>
                  <div className="col-7 fw-bold">{filter.value}</div>
                </div>
              );
            })}
        </div>
        <SummaryTotals
          showTitle={false}
          totals={summary.totalHours}
          billable={summary.billableHours}
          financed={summary.financedHours}
          nonBillable={summary.nonBillableHours}
          internal={summary.internalHours}
          timeOff={summary.timeOffHours}
          isLoading={isLoading}
          avoidCheckMissingHours={true}
        />
        <hr className="header-separator mt-4" />

        <div className="d-flex mt-3 justify-content-between">
          <div
            className="td-task-link mt-2"
            onClick={() => updateAllSelected()}
          >
            Select All
          </div>
          <div className="view-buttons">
            {Object.keys(timeEntriesList).length > 0 && (
              <ActionsButton
                disabled={entriesSelected.length === 0 || isLoading}
                entriesSelected={entriesSelected}
                showActionNonBillaleModal={showActionNonBillaleModal}
                showActionChangeDateModal={showActionChangeDateModal}
                showActionChangeProjectModal={showActionChangeProjectModal}
                updateEntriesSelected={updateEntriesSelected}
                activeUserId={activeUserId}
              />
            )}
            <button
              className="btn btn-primary ms-3"
              onClick={() => updateEntriesSelected(3, null)}
              disabled={
                entriesSelected.length === 0 ||
                isLoading ||
                entriesSelected.some((obj) => obj.userId === activeUserId)
              }
            >
              Approve
            </button>
            <button
              className="btn btn-primary ms-3"
              onClick={() => updateAndViewNext()}
              disabled={
                entriesSelected.length === 0 ||
                isLoading ||
                entriesSelected.some((obj) => obj.userId === activeUserId)
              }
            >
              Approve & view next person
            </button>
          </div>
        </div>

        {isLoading && (
          <Spinner
            style={{
              marginLeft: "45%",
              marginTop: "10%",
            }}
          />
        )}

        {!isLoading &&
          Object.keys(timeEntriesList).length > 0 &&
          Object.keys(timeEntriesList)
            .sort()
            .map((keyName) => {
              let actualSummary = {
                total: 0,
                billable: 0,
                nonBillable: 0,
                internal: 0,
                timeOff: 0,
              };
              timeEntriesList[keyName].forEach((element) => {
                actualSummary[firstLetterToLower(element.timeEntryType)] +=
                  element.hours;
                actualSummary["total"] += element.hours;
              });
              return (
                <>
                  <div
                    className="d-flex mt-4 justify-content-between sticky-date sticky"
                    key={keyName + "-summary"}
                  >
                    <div className="left-side d-flex align-items-center">
                      <BiCalendar style={{ width: "20px", height: "20px" }} />
                      <div className="table-date ms-2">
                        {nameDayDateFormat(
                          timeEntriesList[keyName][0].entryDate
                        )}
                      </div>
                    </div>
                    <DayTotals daySummary={actualSummary} />
                  </div>

                  <table
                    className="table white-table-header-background mt-3 mb-5"
                    style={{ width: "100%" }}
                    key={"table-" + keyName}
                  >
                    <thead className="align-middle sticky-header-with-date sticky">
                      <tr>
                        <th className="text-center border-dark border-top fw-500 text-center py-1 column-5 small">
                          Select
                          <div className="d-flex justify-content-center">
                            <div
                              className="link-text mx-2"
                              onClick={() =>
                                updateSelectedByDay(
                                  timeEntriesList[keyName][0].entryDate,
                                  true
                                )
                              }
                            >
                              All
                            </div>
                            /
                            <div
                              className="link-text mx-2"
                              onClick={() =>
                                updateSelectedByDay(
                                  timeEntriesList[keyName][0].entryDate,
                                  false
                                )
                              }
                            >
                              None
                            </div>
                          </div>
                        </th>
                        <th className="border-dark border-top fw-500 py-0 ps-4 column-20">
                          Client / Project / Task
                        </th>
                        <th className="border-dark border-top fw-500 py-0 ps-4 column-15">
                          Hours / Task Type
                        </th>
                        <th className="border-dark border-top fw-500 py-0 ps-4 column-30">
                          Description
                        </th>
                        <th className="border-dark border-top fw-500 py-0 ps-4 column-15">
                          Billable/Price
                        </th>
                        <th className="border-dark border-top fw-500 py-0 column-5">
                          Status
                        </th>
                        <th className="border-dark border-top fw-500 py-0 ps-3 column-15">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {timeEntriesList[keyName].map(
                        (entry: TimeEntryApprovalTime) => {
                          return (
                            <tr
                              key={"row-" + entry.timeEntryId}
                              style={{ verticalAlign: "middle" }}
                            >
                              <td className="table-td text-center py-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={isSelected(entry)}
                                  disabled={entry.source !== THE_HUB}
                                  onChange={() => updatedSelected(entry)}
                                />
                              </td>
                              <td className="table-td ps-4 py-3 fw-500">
                                {entry.categoryId !== 3 && (
                                  <>
                                    <div className="td-small-text">
                                      {entry.clientName}
                                    </div>
                                    <a
                                      className="td-task-link"
                                      href={navigateTo(entry)}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      Project{" "}
                                      {entry.projectId
                                        ? entry.projectId
                                        : entry.entityId}{" "}
                                      - {entry.entityDescription}
                                    </a>
                                  </>
                                )}
                                {entry.categoryId === 3 && (
                                  <>
                                    <div className="td-small-text">
                                      {entry.clientName}
                                    </div>
                                    <a
                                      className="td-task-link"
                                      href={navigateTo(entry)}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      Work Order {entry.entityId} -{" "}
                                      {entry.entityDescription}
                                    </a>
                                  </>
                                )}
                                <div className="td-small-text mt-1">
                                  {getUserName(entry.userId)}
                                </div>
                              </td>
                              <td className="table-td ps-4 py-3 fw-500">
                                <div className="td-bold">
                                  {quantityFormat(entry.hours)}
                                </div>
                                <div className="td-text">{entry.taskType}</div>
                              </td>
                              <td className="table-td ps-4 py-3 fw-500">
                                <div className="td-text text-wrap">
                                  {entry.description}
                                </div>
                              </td>
                              <td className="table-td ps-4 py-3 fw-500">
                                <div className="td-bold">
                                  {entry.timeEntryType === BILLABLE
                                    ? "Yes"
                                    : entry.timeEntryType === UNPAID
                                    ? "Unpaid"
                                    : "No"}
                                  {entry.timeEntryTypeId === 3
                                    ? "-Financed"
                                    : ""}
                                </div>
                                {entry.timeEntryType === BILLABLE && (
                                  <div className="td-small-text">
                                    {entry.userPriceLevel}
                                  </div>
                                )}
                                {nonBillableIds.includes(
                                  entry.timeEntryTypeId.toString().toLowerCase()
                                ) && (
                                  <div className="td-small-text">
                                    {entry.nonBillableReason}
                                  </div>
                                )}
                                <div className="td-text">
                                  ${quantityFormat(entry.price)}
                                </div>
                              </td>
                              <td className="table-td py-4 fw-500">
                                <div
                                  className={`td-status ${entry.status} ${
                                    entry.status === LOCKED ? "d-flex" : ""
                                  }`}
                                >
                                  {entry.status}
                                  {entry.status === LOCKED && (
                                    <BiLockAlt
                                      className="ms-1"
                                      style={{ width: "16px", height: "16px" }}
                                    />
                                  )}
                                </div>
                              </td>
                              <td className="table-td action-icons-td py-4">
                                <div className="d-flex flex-column">
                                  {(entry.timeEntrySourceId === 3 ||
                                    (entry.timeEntrySourceId === 2 &&
                                      !entry.sourceUrl)) && (
                                    <div className="entry-text-imported link-black">
                                      From {entry.source}
                                    </div>
                                  )}
                                  {entry.timeEntrySourceId === 2 &&
                                    entry.sourceUrl && (
                                      <Link
                                        to={entry.sourceUrl}
                                        className="entry-text-imported text-decoration-none"
                                      >
                                        From {entry.source}
                                      </Link>
                                    )}
                                  <div>
                                    <Tooltip
                                      title={<b>More Info</b>}
                                      placement="top"
                                      TransitionComponent={Zoom}
                                      arrow
                                    >
                                      <span>
                                        <BiInfoCircle
                                          className={`icon-action ${
                                            !isLoading ? "" : "disabled"
                                          }`}
                                          onClick={() => showMoreInfo(entry)}
                                        />
                                      </span>
                                    </Tooltip>
                                    <Tooltip
                                      title={<b>Edit</b>}
                                      placement="top"
                                      TransitionComponent={Zoom}
                                      arrow
                                    >
                                      <span>
                                        <BiEditAlt
                                          className={`icon-action ${
                                            !isLoading &&
                                            iconEditEnabled(
                                              entry,
                                              activeUserId,
                                              entry.userId,
                                              isSuperTimeAdmin,
                                              isSupervisorOrPO,
                                              false,
                                              previousWorkableDay,
                                              today,
                                              lastWorkableDay
                                            )
                                              ? ""
                                              : "disabled"
                                          }`}
                                          onClick={() =>
                                            !isLoading &&
                                            iconEditEnabled(
                                              entry,
                                              activeUserId,
                                              entry.userId,
                                              isSuperTimeAdmin,
                                              isSupervisorOrPO,
                                              false,
                                              previousWorkableDay,
                                              today,
                                              lastWorkableDay
                                            )
                                              ? editEntry(entry)
                                              : undefined
                                          }
                                        />
                                      </span>
                                    </Tooltip>
                                    <Tooltip
                                      title={<b>Delete</b>}
                                      placement="top"
                                      TransitionComponent={Zoom}
                                      arrow
                                    >
                                      <span>
                                        <BiTrash
                                          className={`icon-action ${
                                            !isLoading &&
                                            iconDeleteEnabled(
                                              entry,
                                              activeUserId,
                                              entry.userId,
                                              isSuperTimeAdmin,
                                              isSupervisorOrPO,
                                              false,
                                              previousWorkableDay,
                                              today,
                                              lastWorkableDay
                                            )
                                              ? ""
                                              : "disabled"
                                          }`}
                                          onClick={() =>
                                            !isLoading &&
                                            iconDeleteEnabled(
                                              entry,
                                              activeUserId,
                                              entry.userId,
                                              isSuperTimeAdmin,
                                              isSupervisorOrPO,
                                              false,
                                              previousWorkableDay,
                                              today,
                                              lastWorkableDay
                                            )
                                              ? deleteEntry(entry)
                                              : undefined
                                          }
                                        />
                                      </span>
                                    </Tooltip>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </>
              );
            })}

        <Footer />
      </div>
    </>
  );
};
