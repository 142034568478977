import Footer from "../components/Footer";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { ROUTE_TIMESHEETS } from "../utils/constants";
import eventHandler from "../utils/eventHandler";
import { changeListView } from "../../TimesModule/redux/reducers/times.reducer";
import { BiError } from "react-icons/bi";
import { useHome } from "../../TimesModule/hooks/useHome";
import { dateFormatEntryEdit, quantityFormat } from "../utils/formatters";
import { Spinner } from "../components/Spinner";

export const HomePage = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    activeUserName,
    isLoading,
    todayHours,
    weeklyHours,
    scheduledHours,
    missingTime,
  } = useHome();

  useEffect(() => {
    document.title = "The Hub - Home";
  }, []);

  const clickHours = (goToListView: boolean) => {
    dispatch(changeListView(goToListView));
    if (location.pathname === ROUTE_TIMESHEETS) {
      eventHandler.dispatch("changeToSheetView", {
        isSheetView: !goToListView,
      });
    }
  };

  return (
    <>
      <div className="content">
        <div className="content-header w-100 m-0">
          <h2>Home</h2>
        </div>
        <div className="color-neutrals-800">
          Welcome Back {activeUserName?.split(" ")[0]} 🙂
        </div>

        {isLoading && (
          <Spinner
            style={{
              marginLeft: "45%",
              marginTop: "10%",
            }}
          />
        )}

        {!isLoading && (
          <div className="card entry-hour-box-background px-4 py-3 my-3 w-50">
            <div className="home-card-title">Time this week</div>
            <div className="card my-3">
              <div className="d-flex justify-content-around">
                <div className="left-side-card py-3">
                  <div className="card-hours">
                    {quantityFormat(todayHours)} hrs
                  </div>
                  <div className="card-label">Entered today</div>
                  <Link to={ROUTE_TIMESHEETS} onClick={() => clickHours(true)}>
                    Enter Time
                  </Link>
                </div>
                <div className="right-side-card py-3">
                  <div className="card-hours">
                    {quantityFormat(weeklyHours)}{" "}
                    <span className="totals">
                      / {quantityFormat(scheduledHours * 5)} hrs
                    </span>
                  </div>
                  <div className="card-label">Entered this week</div>
                  <Link to={ROUTE_TIMESHEETS} onClick={() => clickHours(false)}>
                    View this week
                  </Link>
                </div>
              </div>
              {missingTime.missingTime && (
                <div className="card-error with-border mt-3">
                  <div className="error-message py-1 d-flex">
                    <BiError className="error-icon big ms-3 mt-2" />
                    <span className="black-text mx-3">
                      <span className="red-text me-1">Missing time.</span>
                      You are missing {missingTime.hours} hours between{" "}
                      {dateFormatEntryEdit(missingTime.startDate)} and{" "}
                      {dateFormatEntryEdit(missingTime.endDate)}. Contact your
                      PM or Supervisor to get them entered.
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <Footer />
      </div>
    </>
  );
};
