import { createSlice } from "@reduxjs/toolkit";

type MealBreakReportState = {
  isLoading: boolean;
  payPeriods: [];
  mealBreakUserList: any;
};

const initialState: MealBreakReportState = {
  isLoading: true,
  payPeriods: [],
  mealBreakUserList: null,
};

export const mealBreakReportSlice = createSlice({
  name: "mealBreakReport",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPayPeriods: (state, action) => {
      state.payPeriods = action.payload;
    },
    mealBreakReportInfo: (state, action) => {
      state.mealBreakUserList = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setPayPeriods, mealBreakReportInfo } =
  mealBreakReportSlice.actions;

export const SelectPayPeriods = (state: any) => {
  return state.mealBreakReport.payPeriods;
};

export const GetMealBreakUserList = (state: any) => {
  return state.mealBreakReport.mealBreakUserList;
};

export default mealBreakReportSlice.reducer;
