import { useDispatch, useSelector } from "react-redux";
import { selectMetaTimesData } from "../redux/reducers/metaTimes.reducer";
import {
  GetFilters,
  SelectInitialStateFilters,
} from "../redux/reducers/timesConfirmationReport.reducer";
import { actualUIState } from "../../SharedModule/redux/reducers/uiShared.reducer";
import { hasTimeAdminAccess } from "../utils/timesUtils";
import { useCallback, useEffect } from "react";
import {
  IsLoading,
  loadTimeConfirmationInfo,
  selectOrderedBy,
  setLoading,
} from "../redux/reducers/timesConfirmationResults.reducer";
import { TimesheetsService } from "../services/timesheets";

export const useTimesConfirmationResults = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(IsLoading);
  const MetaTimesInfo = useSelector(selectMetaTimesData);
  const filters = useSelector(GetFilters);
  const initialStatefilters = useSelector(SelectInitialStateFilters);
  const { userRolesTimesModule, asuiteTimesPermissions, isLoadingUserInfo } =
    useSelector(actualUIState);
  // times module admin
  const isTimeAdmin = hasTimeAdminAccess(userRolesTimesModule);

  const { timeConfirmationUsersList } = useSelector(selectOrderedBy);

  const loadTimeConfirmationResults = useCallback(async () => {
    dispatch(setLoading(true));
    const results = await TimesheetsService.getTimeConfirmationInfo({
      ...filters,
      costCentersIDs: filters.costCentersIDs
        ? filters.costCentersIDs.map((elem) => elem.value)
        : null,
    });
    dispatch(loadTimeConfirmationInfo(results));
  }, [dispatch, filters]);

  useEffect(() => {
    loadTimeConfirmationResults();
  }, [loadTimeConfirmationResults]);

  return {
    isLoading,
    MetaTimesInfo,
    filters,
    initialStatefilters,
    userRolesTimesModule,
    asuiteTimesPermissions,
    isLoadingUserInfo,
    isTimeAdmin,
    timeConfirmationUsersList,
  };
};
